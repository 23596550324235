<template>
	<v-container>
		<v-layout justify-center wrap>
			<v-flex xs12>
				<v-tabs v-model="selectedEditorIndex" grow slider-color="primary">
					<v-tab>{{ $t('cgu.accounting_firm_terms_of_service', { accountingFirm: accountingFirm.name }) }}</v-tab>
					<v-tab>{{ $t('cgu.accounting_firm_terms_and_conditions', { accountingFirm: accountingFirm.name }) }}</v-tab>
				</v-tabs>
			</v-flex>
			<v-flex xs12>
				<w-rich-text-editor
					v-if="showRichText"
					v-model="richTextModel"
					mode="full"
					:placeholder="richTextPlaceholder"
					:editor-toolbar="editorToolbar"
					:disabled="isDisabled"
				/>
			</v-flex>
			<v-flex text-xs-center>
				<w-btn :loading="isLoading" :disabled="isDisabled" color="primary" @click="save">
					{{ $t('actions.save') }}
				</w-btn>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'
import TermsOfServiceModuleGuard from '@/mixins/ModulesGuards/TermsOfService/TermsOfServiceModuleGuard'

export default {
	name: 'TermsOfService',
	mixins: [TermsOfServiceModuleGuard],
	data: function () {
		return {
			isLoading: false,
			selectedEditorIndex: 0,
			content: {
				termsOfService: '',
				termsOfUse: ''
			},
			placeholders: {
				termsOfService: this.$t('cgu.editor_placeholder_termsOfService'),
				termsOfUse: this.$t('cgu.editor_placeholder_termsOfUse')
			},
			editorToolbar: [
				['bold', 'italic', 'underline', 'strike'],
				['blockquote', 'code-block'],
				[{ header: 1 }, { header: 2 }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				[{ script: 'sub' }, { script: 'super' }],
				[{ indent: '-1' }, { indent: '+1' }],
				[{ direction: 'rtl' }],
				[{ size: ['small', false, 'large', 'huge'] }],
				[{ header: [1, 2, 3, 4, 5, 6, false] }],
				[{ font: [] }],
				[{ align: [] }],
				['clean']
			],
			showRichText: false
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		}),
		isDisabled: function () {
			return this.content === null || this.isLoading
		},
		richTextModel: {
			get: function () {
				return [this.content.termsOfService, this.content.termsOfUse][this.selectedEditorIndex]
			},
			set: function (value) {
				if (this.selectedEditorIndex === 0) {
					return (this.content.termsOfService = value)
				}
				this.content.termsOfUse = value
			}
		},
		richTextPlaceholder: function () {
			return [this.placeholders.termsOfService, this.placeholders.termsOfUse][this.selectedEditorIndex]
		}
	},
	created: async function () {
		this.isLoading = true
		try {
			const { termsOfService, termsOfUse } = await this.service.getTermsOfService(this.accountingFirm.holding_id)
			this.content.termsOfService = termsOfService ?? ''
			this.content.termsOfUse = termsOfUse ?? ''
		} catch (error) {
			// eslint-disable-line no-empty
		} finally {
			this.isLoading = false
			this.showRichText = true
		}
	},
	methods: {
		save: async function () {
			this.isLoading = true
			await this.service.setTermsOfService(this.accountingFirm.holding_id, this.content)
			this.isLoading = false
		}
	}
}
</script>
